import * as Sentry from "@sentry/browser"

import { getCurrentUser } from "src/helpers/user"

Sentry.init({
  dsn: window.sentryDsn,
  tunnel: "/sentry_tunnel",
  denyUrls: [
    "/froala_editor.min.js",
    "/froala-editor/",
    "intercomcdn.com/",
    "intercom.io/"
  ],
  ignoreErrors: [
    "Non-Error exception captured",
    "Non-Error promise rejection captured",
    "SentryError: HTTP Error (429)",
    "Failed to fetch"
  ],
  // eslint-disable-next-line new-cap
  integrations: [new Sentry.browserTracingIntegration()],
  transport: Sentry.makeBrowserOfflineTransport(Sentry.makeFetchTransport),
  tracesSampler: (_samplingContext) => {
    if (window.sentryPerformanceMonitorEnabled) {
      return 0.2 // 20% of transactions are sent to Sentry
    }
    return false
  },

  beforeSend(event) {
    const modifiedEvent = event
    try {
      // IE throws SecurityError when other browser don't
      if (window.navigator.userAgent.indexOf("Trident") > -1) {
        if (event.message && event.message.match(/SecurityError/)) {
          return null
        }
      }

      const currentUser = getCurrentUser()
      modifiedEvent.user = {
        id: currentUser.id,
        email: currentUser.email
      }
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e)
    }

    return modifiedEvent
  }
})
